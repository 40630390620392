import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_toast_container = _resolveComponent("toast-container")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { mode: "ios" }, {
    default: _withCtx(() => [
      (_ctx.environment != 'production')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({'environment-banner': true, 'environment-banner-beta': _ctx.environment == 'beta'})
          }, _toDisplayString(_ctx.environment.toUpperCase()), 3))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_router_outlet),
      _createVNode(_component_toast_container)
    ]),
    _: 1
  }))
}