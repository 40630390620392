<template>
    <ion-page>
        <app-header v-if="isDashboardActive"></app-header>

        <ion-tabs>
            <ion-router-outlet />

            <app-navbar></app-navbar>
        </ion-tabs>
    </ion-page>
</template>

<script>
    import { IonPage, IonTabs, IonRouterOutlet } from '@ionic/vue';

    import AppHeader from '@/components/Layout/AppHeader.vue';
    import AppNavbar from '@/components/Layout/AppNavbar.vue';

    export default {
        name: 'MainPage',

        components: {
            IonPage, IonTabs, AppHeader, IonRouterOutlet, AppNavbar
        },

        computed: {
            isDashboardActive() {
                return false;
                // return this.$route.path.indexOf('/app/dashboard') === 0;
            }
        }
    };
</script>